<template>
<div>

    <v-row align="center" class=" mb-3">
        <v-col cols="12" md="2" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-add`)|| $store.state.user.userType.id == $global.state.roleType.admin">
            <v-btn @click="openAddDialog()" color="primary" large class="btn">
                <v-icon class="ml-2">add_circle_outline</v-icon>
                <h3>إضافة {{this.$route.meta.single}}</h3>
            </v-btn>
        </v-col>
        <v-spacer />
        <v-col cols="12" md="3">
            <v-text-field v-debounce:500ms="getItems" class="mr-1" v-model="searchName" label="بحث حسب الاسم" hide-details="" prepend-inner-icon="search" dense="" outlined="" filled="" />
        </v-col>
        <aAutoComplete v-model="governorateId" :items="$global.state.governorates" item-text="name" item-value="id" title="المحافظة" @input="getItems()" :cols="12" :md="2" :dense="true" :required="false" :fieldTitle="false" />
    </v-row>
 
    <v-card outlined>
        <v-data-table :items-per-page="items.length" :items="items" :headers="headers" :loading="loading" hide-default-footer no-data-text="لا توجد بيانات" loading-text="جاري تحميل البيانات" :id="$vuetify.theme.dark ? 'row_colors_dark' : 'row_colors_light'">
            <template v-slot:item.actions="{item}">

                <v-tooltip bottom="" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-delete`) || $store.state.user.userType.id == $global.state.roleType.admin">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="deleteItem(item.id)" v-on="on" icon>
                            <v-icon color="error">
                                fi fi-rr-trash
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>حذف</span>
                </v-tooltip>

                <v-tooltip bottom="" v-if="$store.state.user.roles.find(x => x.key == `${$route.path.slice(1)}-update`)|| $store.state.user.userType.id == $global.state.roleType.admin">
                    <template v-slot:activator="{ on }">
                        <v-btn @click="openEditDialog(item)" v-on="on" icon>
                            <v-icon color="info">
                                fi fi-rr-edit
                            </v-icon>
                        </v-btn>
                    </template>
                    <span>تعديل</span>
                </v-tooltip>

            </template>
        </v-data-table>
    </v-card>
    <Pagination />
    <Dialog />
</div>
</template>

<script>
export default {

    components: {
        Dialog: () => import("./dialog"),
    },

    data() {
        return {
            loading: false,
            searchName:"",
            governorateId:"",
            headers: [
                {
                    text: "المحافظة",
                    value: "governorate.name"
                },
                {
                    text: "المنطقة",
                    value: "name"
                },
                {
                    text: "الاجراءات",
                    value: "actions"
                }
            ],

            items: []
        }
    },

    created() {
        this.getItems()
        setTimeout(() => {
            this.$eventBus.$on(`Area`, () => {
                this.getItems()
            })
        }, 100);
    },

    beforeDestroy() {
        this.$eventBus.$off(`Area`)
    },

    methods: {
        async getItems() {
            this.loading = true
            try {
                let res = await this.$http.get(`Area?PageIndex=${this.$global.state.filter.pageIndex}&PageSize=${this.$global.state.filter.pageSize}&Name=${this.searchName}&GovernorateId=${this.governorateId}`)
                this.items = res.data.result
                this.$global.state.count = res.data.count
                this.$global.state.length = res.data.count == undefined ? 0 : Math.ceil(res.data.count / this.$global.state.filter.pageSize);
            } catch (err) {
                console.log(err)
            } finally {
                this.loading = false
            }
        },

        async deleteItem(id) {
            this.$genericService.swalAlertConfirm({}).then((result) => {
                if (result.isConfirmed) {
                    this.$genericService
                        .delete(`Area`, id)
                        .then((res) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `تم الحذف بنجاح`,
                                type: "success",
                            });
                            this.getItems();
                        })
                        .catch((err) => {
                            this.$store.dispatch("toastification/setToast", {
                                message: `حصل خطا اثناء الحذف`,
                                type: "error",
                            });
                        });
                }
            });
        },

        openAddDialog() {
            var item = {
                dialogType: 'add'
            }
            this.$store.commit('setItemDetails4', item)
            this.$store.commit('setDialog4')
        },

        openEditDialog(item) {
            item.dialogType = 'edit'
            this.$store.commit('setItemDetails4', item)
            this.$store.commit('setDialog4')
            this.$eventBus.$emit('fill-fields')
        },
    },

}
</script>
